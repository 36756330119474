import React from "react";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/487x486.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img02} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  The World’s <span>1st ICO</span> Platform That Offers
                  Profit-Sharing Dividends
                </h2>
              </div>
              <p>
                As an investor, you play an active role in our success. By
                holding GoldZTokens, you automatically become eligible to
                receive dividends. The more tokens you hold in your wallet, the
                more dividends you'll earn, creating a rewarding incentive for
                your investment.
              </p>
              <Link to="/" className="btn">
                Purchase Tokens
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
