import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        <WhoWeAre />

        {/* <TopPartners /> */}
        <WhyChooseUs />
        {/* <Sales /> */}

        <div className="area-bg">
          {/* <Roadmap /> */}
          <WhitePaper />
        </div>
        <div>
          <section id={"documents"} className="pt-60">
            <iframe
              src="https://app.uniswap.org/#/swap?exactField=input&exactAmount=5&inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0xe6e3499bb5efa06548de26e3350e8bebdde46505"
              height="600px"
              width="100%"
              style={{
                border: 0,
                margin: "0 auto",
                marginBottom: ".5rem",
                display: "block",
                borderRadius: "10px",
                maxWidth: "1070px",
                minWidth: "300px",
              }}
            />
          </section>
        </div>
        {/* <TeamOne /> */}
        <ContactOne />
      </main>
    </LayoutOne>
  );
};

export default Home;
