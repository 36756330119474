import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/images/document_img.png";

const WhitePaper = () => {
  return (
    <section id={'documents'} className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
   

          <div className="">
            <div
              className="document-content wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">Whitepaper</span>
                <h2 className="title">
                  Read GoldZ <span>Whitepaper</span>
                </h2>
              </div>

              <ul className="document-list ">
                <li>White Paper</li>
              </ul>
              <a className="bg-primary btn pb-20" href={require("./GOLD-WHITEPAPER.pdf")} download="gold-z-whitepaper.pdf">Download White paper</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
