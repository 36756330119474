import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import icon04 from "../../assets/img/icon/choose_icon04.svg";
import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Trust and Experience",
      description:
        "GoldZ is not just another crypto token, we have a proven track record in the industry. Our team comprises seasoned experts with years of experience in gold mining and asset management, ensuring your investments are in safe hands.",
    },
    {
      src: icon02,
      alt: "",
      link: "/",
      title: "Profit-Sharing Dividends",
      description:
        "As an investor in GoldZ Token, you become eligible for dividend rewards. The more tokens you hold in your wallet, the higher your dividend earnings. Our commitment to distributing dividends to our loyal investors sets us apart from the rest.",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: "Tangible Assets",
      description: "Unlike other cryptocurrencies, our token is backed by real-world assets. The funds raised through GoldZ's ICO will be used to acquire valuable assets and invest in gold mining ventures, providing a strong foundation for long-term growth and stability.",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Transparency and Accountability",
      description:
        "We value trust and transparency above all. GoldZ Token ensures that every investor is kept well-informed about the progress of our asset acquisitions and gold mining operations.",
    },
    // {
    //   src: icon01,
    //   alt: "",
    //   link: "/",
    //   title: "Lifetime free transaction",
    //   description: "Add new, trending and rare artwork to your collection.",
    // },
    // {
    //   src: icon03,
    //   alt: "",
    //   link: "/",
    //   title: <>Protect the identity</>,
    //   description: "Add new, trending and rare artwork to your collection.",
    // },
    // {
    //   src: icon04,
    //   alt: "",
    //   link: "/",
    //   title: "Sercurity & control over money",
    //   description: "Add new, trending and rare artwork to your collection.",
    // },
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section id={'why-choose-us'} className="choose-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">why Choose us </span>
              <h2 className="title">
                Why choose our GoldZ <span>Token</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="slide-progressbar">
          <div
            style={{
              // width: `${count * toMultiply}%`,
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div>

        {/* <div
          className="slide-progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span className="slider__label sr-only"></span>
        </div> */}
      </div>
    </section>
  );
};

export default WhyChooseUs;
